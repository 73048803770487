/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  
  // Variables globales -------
  var body    = $('body');

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        $(function () {
          $('[data-toggle="popover"]').popover();
          $('.delay_popover').popover({
            placement : 'top',
            html : true,
            trigger : 'hover',
            delay: { 
               hide: "800"
            }
          });
        });

        body.on('click', function (e) {
            $('[data-toggle=popover]').each(function () {
                // hide any open popovers when the anywhere else in the body is clicked
                if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
                    $(this).popover('hide');
                }
            });
        });

        body.imagesLoaded( { background: true }, function() {
          console.log('images loaded');
          body.addClass('render');
        });

        // Menu hover ------
        $('.navbar-nav li.dropdown').hover(function() {
          $(this).find('.dropdown-menu').stop(true, true).delay(0).fadeIn(250);
        }, function() {
          $(this).find('.dropdown-menu').stop(true, true).delay(250).fadeOut(250);
        });

        var openMenu  = $('.link-open-menu'),
            closeMenu = $('.close_menu');

        openMenu.on('click touch', function() {
          body.toggleClass('show-menu');
        });

        closeMenu.on('click touch', function() {
          body.removeClass('show-menu');
        });

        $('.image_modal').venobox({
          arrowsColor: '#48773E',
          closeBackground: '#48773E',
          closeColor: '#ffffff',
          overlayColor: 'rgba(255,255,255,0.85)'
        });

        // Revisar cookie y correr codigo
        // window.onload = function () {
        //   if (localStorage.getItem("hasCodeRunBefore") === null) {
        //       $('.lastweek').venobox({
        //         arrowsColor: '#233DF9',
        //         closeBackground: '#DB2A60',
        //         closeColor: '#ffffff',
        //         overlayColor: 'rgba(255,255,255,0.85)',
        //         framewidth: '1080px'
        //       }).trigger('click');
        //       localStorage.setItem("hasCodeRunBefore", true);
        //   }
        // };

        // $(function(){
        //   $( 'a[href="#"]' ).click( function(e) {
        //     e.preventDefault();
        //   });
        // });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        $(document).ready(function(){
          $('.intro').slick({
            dots: true,
            // arrows: false,
            adaptiveHeight: true,
            infinite: true,
            speed: 500,
            fade: true,
            autoplay: true,
            prevArrow: '<span class="slick-prev material-icons">arrow_back_ios</span>',
            nextArrow: '<span class="slick-next material-icons">arrow_forward_ios</span>',
          });

          var navArticle = $('.navArticle');

          navArticle.click(function(e) {
            e.preventDefault();
            navArticle.removeClass('active');
            var slideno = $(this).data('slide');
            $('.intro').slick('slickGoTo', slideno - 1);
            $(this).addClass('active');
          });
        }); 
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Páginas de unete -----------------------------
    'page_template_template_secciones_unete': {
      init: function() {
        console.log("Unete");
      },
      finalize: function() {
        const uneteTitle = document.querySelector('.unete_title'),
              sideA = document.querySelector('.side_a'),
              sideB = document.querySelector('.side_b'),
              gPrev = document.querySelector('.g_prev'),
              gNext = document.querySelector('.g_next');

        var tl = gsap.timeline();

        tl.from(uneteTitle, {duration:0.6, ease: "power3.out", opacity: 0, x: -200});
        tl.from(sideA, {duration:0.6, ease: "power3.out", opacity: 0, x: -200});
        tl.from(sideB, {duration:0.6, ease: "power3.out", opacity: 0, x: 200});

        if (gPrev) {
          gsap.from(gPrev, {
            scrollTrigger: {
              trigger: gPrev,
              start: "10px 90%",
              toggleActions: "restart none none restart"
            },
            duration:1,
            ease: "power3.out",
            opacity:0,
            x: -200
          });
        }
        if (gNext) {
          gsap.from(gNext, {
            scrollTrigger: {
              trigger: gNext,
              start: "10px 90%",
              toggleActions: "restart none none restart"
            },
            duration:1,
            ease: "power3.out",
            opacity:0,
            x: 200
          });
        }
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'noticias': {
      init: function() {
        var noticias = {
          galleryFn: function(){
            $('.gallery').venobox({
              arrowsColor: '#233DF9',
              closeBackground: '#DB2A60',
              closeColor: '#ffffff',
              overlayColor: 'rgba(255,255,255,0.85)'
            });
          },
          expandFn: function () {
            $(".expand_noticia").click(function (event) {
              event.preventDefault();
              var excerpt = $(this).closest('.row').find('.excerpt');
              excerpt.hide();
              var complete = $(this).closest('.row').find('.complete');
              complete.show();
              $( this ).hide();
            });
          }
        };
        noticias.galleryFn();
        noticias.expandFn();
       
        window.almComplete = function(alm){
          // console.log("Ajax Load More Complete!");
          noticias.galleryFn();
          noticias.expandFn();
        };
      }
    },
    'banco_de_proyectos': {
      init: function() {
        console.log("BDP");
        // gsap.from(".grid_item", {duration:0.6, delay: 0.5, stagger: 0.2, scale: 0.5, ease: "back", opacity:0, y:200});
        gsap.from(".grid_item", {
          duration:1, delay: 0.5, stagger: 0.5, opacity:0
        });

        if ($(window).width() > 768) {
          console.log("more than");
          gsap.from(".area_card", {
            scrollTrigger: {
              trigger: ".area_card",
              toggleActions: "restart pause resume none",
            },duration:0.6, stagger: 0.3, opacity: 0, y: 50});

          gsap.from(".paso_wrap", {
            scrollTrigger: {
              trigger: ".paso_wrap",
              toggleActions: "restart pause resume none",
            },duration:0.6, stagger: 0.3, opacity: 0, y: 50});

          gsap.to(".image_1", {
            scrollTrigger: {
              trigger: ".text_2",
              start: "top 80%",
              end: "top 100px",
              scrub: true
            },
            opacity:0});

          gsap.from(".image_2", {
            scrollTrigger: {
              trigger: ".text_2",
              start: "top 80%",
              end: "top 100px",
              scrub: true
            },
            opacity: 0});

          gsap.from(".image_3", {
            scrollTrigger: {
              trigger: ".text_3",
              start: "top 80%",
              end: "top 100px",
              scrub: true
            },
            x: 960});

          gsap.to("#bg_1", {
            scrollTrigger: {
              trigger: ".text_2",
              start: "top 80%",
              end: "top 100px",
              scrub: true
            },
            background: "#02BDE5"});

          gsap.from("#bg_2", {
            scrollTrigger: {
              trigger: ".text_3",
              start: "top 80%",
              end: "top 100px",
              scrub: true
            },
            x: "100%"});

          gsap.from(".logo_ods", {
            scrollTrigger: {
              trigger: ".text_3",
              start: "top 50%",
              end: "top 100px",
              scrub: true
            },
            x: "-100%",
            opacity: 0});
        }
        // close if window width

      }
    },
    'page_template_template_banco': {
      init: function() {
        var openBDP  = $('.open_bdp'),
            closeBDP = $('.close_bdp'),
            openFilters = $('.open_filters'),
            closeFilters = $('.close_filters');

        openBDP.on('click touch', function() {
          body.toggleClass('show_bdp');
        });

        closeBDP.on('click touch', function() {
          body.removeClass('show_bdp');
        });

        function changeTextBDP() {
          if ($(window).width() < 768) {
            openBDP.html('Qué es el BDP');
          }
        }
        $(window).on('resize', function(){
          changeTextBDP();
        });
        changeTextBDP();

        // Abrir y cerrar filtros -----------
        openFilters.on('click touch', function() {
          body.addClass('show_filters');
        });
        closeFilters.on('click touch', function() {
          body.removeClass('show_filters');
        });

        $('.filters_bdp .bg').on('click touch', function() {
          body.removeClass('show_filters');
        });

        // Añadir titulos en la modal de filtros ------------------
        $('<h5>Áreas Temáticas</h5>').prependTo('#alm-filter-1');
        $('<h5>Tipos de Proyectos</h5>').prependTo('#alm-filter-2');
        $('<h5>ODS que inciden en el proyecto</h5>').prependTo('#alm-filter-3');

        window.almComplete = function(alm){
          console.log("Proyectos cargados");
        };

        window.almFilterComplete = function(){
          console.log('Filtro completo');
          body.removeClass('show_filters');
        };

        window.almOnChange = function(alm){
          console.log("Aplicando cambios");
          // $(numFilters);
        };
      }
    },
    'single_iniciativa_single': {
      init: function() {

        console.log("Iniciativa single");

        const heroImage = document.querySelector('.hero_image'),
              heroDetails = document.querySelector('.hero-details'),
              solucionIntro = document.querySelector('.solucion_intro'),
              comments = document.querySelector('.comments');

        const projectImages = document.querySelector('.project_images'),
              video = document.querySelector('.embed_wrap'),
              apoyo = document.querySelector('.apoyo'),
              bottomSection = document.querySelector('.bottom_section');

        gsap.timeline({delay:0.5})
          .from(heroImage, {duration:0.6, ease: "back", opacity: 0, x: -200})
          .from(heroDetails, {duration:0.6, ease: "back", opacity: 0, x: 200});

        gsap.from(solucionIntro, {
          scrollTrigger: {
            trigger: solucionIntro,
            toggleActions: "play none play none",
            start: "10px 90%",
          }, duration:0.6, ease: "back", opacity:0, y: 200
        });

        // gsap.from(projectImages, {
        //   scrollTrigger: {
        //     trigger: projectImages,
        //     toggleActions: "play none play none",
        //     start: "20px 80%",
        //   }, duration:0.6, ease: "back", opacity:0, y:200
        // });

        // gsap.from(video, {
        //   scrollTrigger: {
        //     trigger: video,
        //     toggleActions: "play none play none",
        //     start: "20px 80%",
        //   }, duration:0.6, ease: "back", opacity:0, y:200
        // });

        gsap.from(comments, {
          scrollTrigger: {
            trigger: comments,
            toggleActions: "play none play none",
            start: "20px 80%",
          }, duration:0.6, ease: "back", opacity:0, y:200
        });

        gsap.from(apoyo, {
          scrollTrigger: {
            trigger: apoyo,
            toggleActions: "play none play none",
            start: "20px 80%",
          }, duration:0.6, ease: "back", opacity:0, y:200
        });


        window.onload = function () {
          $('.gallery').venobox({
            arrowsColor: '#233DF9',
            closeBackground: '#DB2A60',
            spinColor: '#DB2A60',
            closeColor: '#ffffff',
            overlayColor: 'rgba(255,255,255,0.85)',
            titleColor: '#ffffff',
            titleBackground: '#030D30'
          });
        };

      }
    },
    'patrocinadores': {
      init: function() {
        console.log("patrocinadores");
        const tl = gsap.timeline();

        const theProject = document.querySelector(".the_project");

        gsap.to(".the_share", {
          scale: 1.2, 
          scrollTrigger: {
            trigger: ".agentes_cambio",
            start: "top 80%",
            end: "top 30%",
            scrub: 1
          }
        });

        if (theProject) {
          console.log("proyecto selecciondo");
          gsap.to(".the_intro", {
            opacity: 0,
            display: "none",
            scrollTrigger: {
              trigger: ".apoyar_proyecto",
              end: "top center",
              scrub: true
            }
          });
          gsap.from(theProject, {
            opacity: 0,
            scrollTrigger: {
              trigger: ".apoyar_proyecto",
              start: "top center",
              end: "top 50px",
              scrub: true
            }
          });
        }
      }
    },
    'cargar_proyecto': {
      init: function() {
        console.log("Cargar Proyecto");

        $( ".wpcf7-validates-as-required" ).parent().addClass( "has-required" );
        
        autosize($('textarea'));

        var loader1 =     $(".ajax-loader.step_1"),
            loader2 =     $(".ajax-loader.step_2"),
            respuesta1 =  $(".respuesta_next_1"),
            respuesta2 =  $(".respuesta_next_2"),
            form =        $('.wpcf7-form');
               
        $( "#next_1" ).click(function() {
          console.log("Siguiente 1");
          loader1.css( "visibility", "visible" );
          respuesta1.css( "display", "none" );
          form.removeClass("invalid");
          $(checkForChanges1);
        });

        function checkForChanges1() {
          if (form.hasClass('invalid')) {
            console.log("Invalido");
            loader1.css( "visibility", "hidden" );
            respuesta1.css( "display", "block" );
          } else {
            setTimeout(checkForChanges1, 500);
          }
        }
        $(checkForChanges1);

        $( "#next_2" ).click(function() {
          console.log("Siguiente 2");
          loader2.css( "visibility", "visible" );
          respuesta2.css( "display", "none" );
          form.removeClass("invalid");
          $(checkForChanges2);
        });

        function checkForChanges2() {
          if (form.hasClass('invalid')) {
            console.log("Invalido");
            loader2.css( "visibility", "hidden" );
            respuesta2.css( "display", "block" );
          } else {
            setTimeout(checkForChanges2, 500);
          }
        }
        // $(checkForChanges2);

        $('.wpcf7-text').on('input', function() {
          $( this ).removeClass("wpcf7-not-valid");
          $( this ).next(".wpcf7-not-valid-tip").css( "display", "none" );
        });

        $('.wpcf7-textarea').on('input', function() {
          $( this ).removeClass("wpcf7-not-valid");
          $( this ).next(".wpcf7-not-valid-tip").css( "display", "none" );
        });

        var setep2 = $("[data-step=2]"),
            setep3 = $("[data-step=3]");

        var progress1 = $(".trx_mscf_progressbar li:first-child"),
            progress2 = $(".trx_mscf_progressbar li:nth-child(2)"),
            progress3 = $(".trx_mscf_progressbar li:nth-child(3)");

        progress1.text("Datos principales");
        progress2.text("Problema y solución");
        progress3.text("Implementación");

        function checkForStep() {
          if (setep2.hasClass('trx_mscf_active')) {
            console.log("Step 2");
            $("html, body").animate({scrollTop: 300}, 500);
            progress1.addClass("check");
          } else {
            setTimeout(checkForStep, 500);
          }
        }

        $(checkForStep);

        function checkForStep3() {
          if (setep3.hasClass('trx_mscf_active')) {
            console.log("Step 3");
            $("html, body").animate({scrollTop: 300}, 500);
            progress2.addClass("check");
          } else {
            setTimeout(checkForStep3, 500);
          }
        }
        $(checkForStep3);
      }
    },
    'volver_a_cargar_proyecto_id3322_previewtrue': {
      init: function() {
        console.log("volver a cargar Proyecto");

        $( ".wpcf7-validates-as-required" ).parent().addClass( "has-required" );
        
        autosize($('textarea'));

        var loader1 =     $(".ajax-loader.step_1"),
            loader2 =     $(".ajax-loader.step_2"),
            respuesta1 =  $(".respuesta_next_1"),
            respuesta2 =  $(".respuesta_next_2"),
            form =        $('.wpcf7-form');
               
        $( "#next_1" ).click(function() {
          console.log("Siguiente 1");
          loader1.css( "visibility", "visible" );
          respuesta1.css( "display", "none" );
          form.removeClass("invalid");
          $(checkForChanges1);
        });

        function checkForChanges1() {
          if (form.hasClass('invalid')) {
            console.log("Invalido");
            loader1.css( "visibility", "hidden" );
            respuesta1.css( "display", "block" );
          } else {
            setTimeout(checkForChanges1, 500);
          }
        }
        $(checkForChanges1);

        $( "#next_2" ).click(function() {
          console.log("Siguiente 2");
          loader2.css( "visibility", "visible" );
          respuesta2.css( "display", "none" );
          form.removeClass("invalid");
          $(checkForChanges2);
        });

        function checkForChanges2() {
          if (form.hasClass('invalid')) {
            console.log("Invalido");
            loader2.css( "visibility", "hidden" );
            respuesta2.css( "display", "block" );
          } else {
            setTimeout(checkForChanges2, 500);
          }
        }
        // $(checkForChanges2);

        $('.wpcf7-text').on('input', function() {
          $( this ).removeClass("wpcf7-not-valid");
          $( this ).next(".wpcf7-not-valid-tip").css( "display", "none" );
        });

        $('.wpcf7-textarea').on('input', function() {
          $( this ).removeClass("wpcf7-not-valid");
          $( this ).next(".wpcf7-not-valid-tip").css( "display", "none" );
        });

        var setep2 = $("[data-step=2]"),
            setep3 = $("[data-step=3]");

        var progress1 = $(".trx_mscf_progressbar li:first-child"),
            progress2 = $(".trx_mscf_progressbar li:nth-child(2)"),
            progress3 = $(".trx_mscf_progressbar li:nth-child(3)");

        progress1.text("Datos principales");
        progress2.text("Problema y solución");
        progress3.text("Implementación");

        function checkForStep() {
          if (setep2.hasClass('trx_mscf_active')) {
            console.log("Step 2");
            $("html, body").animate({scrollTop: 300}, 500);
            progress1.addClass("check");
          } else {
            setTimeout(checkForStep, 500);
          }
        }

        $(checkForStep);

        function checkForStep3() {
          if (setep3.hasClass('trx_mscf_active')) {
            console.log("Step 3");
            $("html, body").animate({scrollTop: 300}, 500);
            progress2.addClass("check");
          } else {
            setTimeout(checkForStep3, 500);
          }
        }
        $(checkForStep3);
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
